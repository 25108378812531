import { Component, Vue, Prop } from 'nuxt-property-decorator';
import DocumentAvailabilityLabel from '@/components/document-availability-label.vue';
import DocumentListItemMetaInfo from '@/components/document-list-item-meta-info.vue';
import DocumentListItemThumbnail from '@/components/document-list-item-thumbnail.vue';
import DocumentTypeIcon from '@/components/document-type-icon.vue';
import ViewerLink from '@/components/viewer-link.vue';
import WklrIconBtn from '@/components/shared/wklr-icon-btn.vue';
import { isExternalViewer } from '@/utility';
import { generateSearchRoute, SearchLocation } from '@/utils/routeUtils';
import * as Constants from '@/constants';
import { BookSnippet, DocRecord, DocRendered, DocumentTypeEnum, QuickAccessItem } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';
import FullverIntroductionDialog from '@/components/dialog/fullver-introduction-dialog.vue';

const isbnLink = (document: DocRecord | BookSnippet): string | null => {
  let isbn = '';
  if ('uri' in document) {
    const parts = document.uri.split(':');
    if (parts.length < 3) return null;
    if (parts[2] === '') return null;
    isbn = parts[2];
  } else if ('isbn' in document) {
    isbn = document.isbn;
  } else {
    return null;
  }
  return `https://ja.wikipedia.org/wiki/%E7%89%B9%E5%88%A5:%E6%96%87%E7%8C%AE%E8%B3%87%E6%96%99?isbn=${isbn}`;
};

@Component({
  components: {
    DocumentAvailabilityLabel,
    DocumentListItemMetaInfo,
    DocumentListItemThumbnail,
    DocumentTypeIcon,
    ViewerLink,
    WklrIconBtn,
    FullverIntroductionDialog,
  },
})
export default class DocumentListItem extends Vue {
  isbnLink = isbnLink;
  isExternalViewer = isExternalViewer;
  isAccessible = isAccessible;

  /** 表示する文書 */
  @Prop() document!: DocRecord | BookSnippet;
  /** 表示する文書に装飾を加えたもの */
  @Prop() documentRendered: DocRendered | undefined;
  get docRendered(): DocRendered {
    // FIXME: Nullish coalescing にしたい
    return this.documentRendered !== null && this.documentRendered !== undefined
      ? this.documentRendered
      : this.document;
  }

  /** デザインバリエーション */
  @Prop() variant!: 'small' | 'toc' | 'directory' | undefined;

  /** メタ情報のドロップダウンなどの機能を無効にする */
  @Prop() disabled!: boolean;

  /** 呼び出し元のUI的にプレビュー表示が可能かどうか（ false の場合はプレビューへのリンクを表示しない） */
  @Prop() isPreviewAvailable!: boolean;

  /** document が BookSnippet だった時にプラン的に閲覧できるか（ドキュメントが BookSnippet だった時に docAccessible が存在しないのでこのフラグを使う） */
  @Prop() isBookSnippetAccessible: boolean | undefined;

  /** emit が最後まで到達する前に遷移が完了されてしまうことがあるので末端のコンポーネントで直接イベントを発火させるためのコールバック */
  @Prop() viewerClickCallback?: () => void;

  /** タイトルに設定されるリンクを開くターゲット */
  @Prop() target?: string;

  /** Store へのリンク */
  @Prop() storeUrl?: string;

  /** ドキュメントのクイックアクセス 配列になっているがブックマークされていないとき0件で取得している場合1件になる */
  quickAccessItem: QuickAccessItem[] | null = null;

  /**
   * ドキュメントへのリンクを無効化する
   * MHM マイページ会員向け環境で使用
   */
  @Prop({ type: Boolean }) disableDocumentLink?: boolean;

  showFullverIntroductionDialog = false;

  hiddenLabelPackIds = [1];

  @Prop({ type: Boolean, default: false })
  hasLatestVersionInStore?: boolean;

  get accessible(): boolean {
    if ('docAccessible' in this.document) return this.document.docAccessible;
    return !!this.isBookSnippetAccessible;
  }

  get actionable(): boolean {
    return this.hasPdf && this.isPreviewAvailable;
  }

  get docRecordType(): DocumentTypeEnum | undefined {
    return 'type' in this.document ? this.document.type : undefined;
  }

  get shouldShowDocumentTypeIcon(): boolean {
    return this.variant === 'small' || (this.variant == 'toc' && this.docRecordType === DocumentTypeEnum.Book);
  }

  get disablePersonalFeature(): boolean {
    return this.$domain.isMHMMypage || this.$domain.isSHKommentar || this.$domain.isSHKommentarLibrary;
  }

  get shouldShowQuickAccessIcon(): boolean {
    return !this.disablePersonalFeature && this.variant === 'toc';
  }

  get isSelectedQuickAccessIcon(): boolean {
    return this.quickAccessItem !== null && this.quickAccessItem.length > 0;
  }

  async updateQuickAccessItem(): Promise<void> {
    if (this.variant !== 'toc') {
      return;
    }

    const result = await this.$repositories.docs.getQuickAccessItems(this.document.id);
    if (result.isFailure()) {
      console.error(result.error);
      return;
    }
    if (!result.value.whole) {
      this.quickAccessItem = [];
      return;
    }
    this.quickAccessItem = [result.value.whole];
  }

  get hasPdf(): boolean {
    if ('type' in this.document && !isAccessible(this.document)) return false;
    return this.document.pdfFileURI !== null && this.document.pdfFileURI.length > 0;
  }

  get shouldShowThumbnail(): boolean {
    return this.docRecordType !== DocumentTypeEnum.Law;
  }

  get pathToLatestBook(): SearchLocation | undefined {
    if (!('latestEditionNaturalId' in this.document)) return;
    if (this.document.latestEditionNaturalId === this.document.id) return;
    return generateSearchRoute(
      { keyword: 'urn:isbn:' + this.document.latestEditionNaturalId },
      Constants.Search.option,
    );
  }

  get purchased(): boolean {
    if (!('purchased' in this.document)) {
      return false;
    }
    return this.document.purchased ?? false;
  }

  showShareUrlDialog(): void {
    this.$store.commit('setShareUrlDialogDocument', this.document);
  }

  async toggleDocumentToQuickAccess(query: string, addedAt: string, index: number) {
    if ('type' in this.document && isAccessible(this.document)) {
      if (this.quickAccessItem === null || this.quickAccessItem.length === 0) {
        try {
          const item = await this.$repositories.quickAccess.createDocumentItem(
            this.document.id,
            // TODO: /api/v2/documents/{id} のレスポンスに docDataUpdatedAt が実装されたらそれを使うように置き換える
            new Date(this.document.publishedOn).getTime() / 1000,
            '',
          );
          this.quickAccessItem = [item];
          this.$toast.success('この文書をクイックアクセスに保存しました');
        } catch (error) {
          console.error(error);
          this.$toast.error('クイックアクセスに保存するのに失敗しました');
        }
      } else {
        try {
          await this.$repositories.quickAccess.deleteItem(
            this.quickAccessItem[0].id,
            this.quickAccessItem[0].updatedAt,
          );
          this.quickAccessItem = [];
          this.$toast.success('この文書をクイックアクセスから削除しました');
        } catch (error) {
          console.error(error);
          this.$toast.error('クイックアクセスから削除するのに失敗しました');
        }
      }
    } else {
      this.$toast.error('想定外の文書をクイックアクセスに登録しようとしています');
    }
  }

  async created() {
    this.updateQuickAccessItem();
  }

  filterByAuthorIndex(index: number, event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByAuthor', this.document.authors[index], event);
  }

  filterByPublisher(event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByPublisher', this.document.publisher, event);
  }

  filterByPublishedOnGte(event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByPublishedOn', 'gte', this.document.publishedOn, event);
  }

  filterByPublishedOnLte(event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByPublishedOn', 'lte', this.document.publishedOn, event);
  }

  assertIsDocRecord(document: DocRecord | BookSnippet): document is DocRecord {
    return 'type' in document;
  }
}
