import type { NewsApi } from '@gen/wklr-backend-api/v1/api/news-api';
import type { KeyCount, NewsWithCategory } from '@gen/wklr-backend-api/v1/model';
import { formatYmd } from '../utility';
import { CacheTTL } from '../constants';

export class NewsRepository {
  constructor(private api: NewsApi) {}

  async getNews(categoryId?: string, offset?: number, limit?: number): Promise<NewsWithCategory[]> {
    const { data } = await this.api.getNews(categoryId, offset, limit);
    data.map((news) => {
      news.publishedOn = formatYmd(news.publishedOn);
    });
    return data;
  }

  async getNewsCount(): Promise<number> {
    const { data } = await this.api.getNewsCount({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data.count;
  }

  async getNewsCountByCategory(): Promise<KeyCount[]> {
    const { data } = await this.api.getNewsCountByCategory();
    return data.counts;
  }
}
