<template>
  <div class="resizable-drawer-layout" :style="{ 'grid-template-columns': gridTemplateColumns }">
    <div
      v-show="isDrawerOpen"
      class="drawer-container"
      :class="{ 'elevation-5': leftElevation }"
      :style="{ width: `${sidebarWidth}px` }"
    >
      <section class="pane">
        <slot name="drawer">
          <!-- ドロワーコンテンツが入る -->
        </slot>
      </section>

      <!-- ドロワーをリサイズするためのハンドル -->
      <div class="resize-handle" @pointerdown="startResize" />
    </div>
    <div class="main-container">
      <section class="pane">
        <slot name="main">
          <!-- メインコンテンツが入る -->
        </slot>
      </section>
    </div>
    <div
      v-if="enableRightDrawer"
      v-show="isRightDrawerOpen"
      :style="{ flexBasis: `${rightDrawerWidth}px`, width: `${rightDrawerWidth}px` }"
      :class="{ 'elevation-5': rightElevation, '-overlay': isOverlayRightDrawer }"
      class="right-drawer-container"
    >
      <section class="pane">
        <slot name="right-drawer">
          <!-- 右ドロワーコンテンツが入る -->
        </slot>
      </section>
    </div>

    <v-btn
      fixed
      dark
      fab
      bottom
      color="grey"
      title="目次パネルを表示・非表示する"
      :style="{ left: toggleButtonPosition }"
      class="btn-toggle-drawer"
      @click="toggleDrawer"
    >
      <v-icon :class="[isDrawerOpen ? 'btn-toggle-drawer-normal' : 'btn-toggle-drawer-rotate']">
        mdi-chevron-double-left
      </v-icon>
    </v-btn>
    <v-btn
      v-if="enableRightDrawer"
      fixed
      dark
      fab
      bottom
      color="grey"
      :style="{ right: rightToggleButtonPosition }"
      class="btn-toggle-drawer"
      @click="toggleRightDrawer"
    >
      <v-icon :class="[isRightDrawerOpen ? 'btn-toggle-drawer-normal' : 'btn-toggle-drawer-rotate']">
        mdi-chevron-double-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Prop, Vue, Watch } from 'nuxt-property-decorator';
import type SearchBar from '@/components/search-bar.vue';

// ドロワーのデフォルトサイズ
const DEFAULT_WIDTH = 256;
// ドロワーの最小サイズ
const MIN_WIDTH = DEFAULT_WIDTH;
// ドロワーの最大サイズ
const MAX_WIDTH = 1000;

/**
 * ドロワーがあり、ドラッグでドロワーの幅を変更できるレイアウト
 * ドロワーとメインエリアは個別にスクロールすることでき、フッターは固定表示になっている
 * TODO: ドロワートグル機能を実装する
 * TODO: ドロワートグルの幅の取り扱いを整理する（初期値だけを Props で受け取る or state を使わずリアクティブに実装する）
 */
@Component
export default class LayoutResizableDrawer extends Vue {
  /** 右ドロワーが表示中か非表示かのフラグ */
  @Prop({ default: false }) enableRightDrawer!: boolean;

  @Prop() initialRightDrawerOpen!: boolean | 'unless-overlay';

  @Prop() leftElevation?: boolean;

  @Prop() rightElevation?: boolean;

  @Inject('$search-bar') searchBar!: InstanceType<typeof SearchBar>;

  /** ドロワーの幅 */
  sidebarWidth = DEFAULT_WIDTH;

  /** ドロワーがリサイズしているかのフラグ。リサイズ中は位置情報を持つ */
  isResizing = false;

  /** ドロワーサイズ変更操作開始位置 */
  startX = 0;

  get gridTemplateColumns(): string {
    const columns = [];
    if (this.isDrawerOpen) {
      columns.push(this.drawerWidth + 'px');
    }
    columns.push('1fr');
    if (this.enableRightDrawer && this.isRightDrawerOpen && !this.isOverlayRightDrawer) {
      columns.push('1fr');
    }

    return columns.join(' ');
  }

  /** ドロワー部分の幅 */
  get drawerWidth(): number {
    return this.isDrawerOpen ? this.sidebarWidth : 0;
  }

  get rightDrawerWidth(): number {
    if (!this.isRightDrawerOpen) return 0;
    return this.isOverlayRightDrawer
      ? (this.$vuetify.breakpoint.width - this.drawerWidth) * 0.8
      : (this.$vuetify.breakpoint.width - this.drawerWidth) / 2;
  }

  get isOverlayRightDrawer(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  /** トグルボタンの位置を px 付きで返す */
  get toggleButtonPosition(): string {
    return this.isDrawerOpen ? `${this.drawerWidth - 16}px` : '6px';
  }

  /** トグルボタンの位置を px 付きで返す */
  get rightToggleButtonPosition(): string {
    return this.isRightDrawerOpen ? `${this.rightDrawerWidth - 16}px` : '6px';
  }

  /** ドロワーが表示中か非表示かのフラグ */
  isDrawerOpen = true;
  @Watch('isDrawerOpen', { immediate: true })
  emitLeftDrawer(val: boolean) {
    if (!this.searchBar) {
      console.error('Cannot find provided search-bar component');
      return;
    }

    // search-bar も同時に表示・非表示の制御を行う
    if (val) {
      this.searchBar.$el.removeAttribute('style');
    } else {
      this.searchBar.$el.setAttribute('style', 'display: none');
    }
  }

  /** ドロワーが表示中か非表示かのフラグ */
  isRightDrawerOpen = true;
  @Watch('isRightDrawerOpen', { immediate: true })
  emitRightDrawer(): void {
    if (this.isRightDrawerOpen) {
      this.$emit('right-drawer-open');
    } else {
      this.$emit('right-drawer-close');
    }
  }

  mounted() {
    this.isRightDrawerOpen =
      this.initialRightDrawerOpen === 'unless-overlay' ? !this.isOverlayRightDrawer : this.initialRightDrawerOpen;
  }

  unmounted() {
    document.removeEventListener('pointermove', this.onMouseMove);
    document.removeEventListener('pointerup', this.stopResize);
    document.removeEventListener('pointercancel', this.stopResize);
  }

  // マウス移動ハンドラ
  onMouseMove(event: MouseEvent) {
    if (!this.isResizing) return;

    const deltaX = event.clientX - this.startX;
    const newWidth = Math.min(Math.max(this.sidebarWidth + deltaX, MIN_WIDTH), MAX_WIDTH);

    this.sidebarWidth = newWidth;
    this.startX = event.clientX;

    if (!this.searchBar) {
      console.error('Cannot find provided search-bar component');
      return;
    }

    let header: HTMLElement | null = null;
    if (this.$store.state.persistent.renewal) {
      header = this.searchBar.$el as HTMLElement | null;
    } else {
      header = this.searchBar.$el.querySelector('#search-bar') as HTMLElement | null;
    }

    if (!header) {
      console.error('Cannot find provided search-bar component');
      return;
    }

    // トランジションを一時的に無効化
    header.style.transition = 'none';

    // search-bar.vue で .document の時に width に使用されている CSS 変数の値を更新
    document.documentElement.style.setProperty(
      '--search-bar-drawer-width',
      this.isDrawerOpen ? `${newWidth}px` : '0px',
    );

    setTimeout(() => {
      if (!header) return;

      // トランジションを有効化
      header.style.transition = '';
    }, 0);
  }

  /** リサイズ開始処*/
  startResize(event: PointerEvent) {
    // タッチ/マウスの両方に対応
    event.preventDefault();

    this.isResizing = true;
    this.startX = event.clientX;

    // グローバルイベントリスナーを追加
    document.addEventListener('pointermove', this.onMouseMove);
    document.addEventListener('pointerup', this.stopResize);
    document.addEventListener('pointercancel', this.stopResize);
  }

  /** リサイズ停止処理 */
  stopResize() {
    if (!this.isResizing) return;

    this.isResizing = false;

    // グローバルイベントリスナーを削除
    document.removeEventListener('pointermove', this.onMouseMove);
    document.removeEventListener('pointerup', this.stopResize);
    document.removeEventListener('pointercancel', this.stopResize);
  }

  /** 情報パネルの表示・非表示 */
  toggleDrawer() {
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  /** 情報パネルの表示・非表示 */
  toggleRightDrawer() {
    this.isRightDrawerOpen = !this.isRightDrawerOpen;
  }
}
</script>

<style lang="scss" scoped>
@import '../../layouts/default.scss';

.resizable-drawer-layout {
  display: grid;
  width: 100%;
  max-width: 100%;
  padding: 0;

  section.pane {
    height: var(--viewport-height-100);
    overflow-y: auto;
  }
}

.drawer-container {
  z-index: $layer-layout-resizable-drawer-left-drawer;
  flex-shrink: 0;
  flex-grow: 0;
}

.right-drawer-container {
  flex-shrink: 0;
  flex-grow: 0;
  background: white;
  border-left: 1px solid #e0e0e0;

  &.-overlay {
    position: fixed;
    right: 0;
    z-index: $layer-layout-resizable-drawer-right-drawer;
  }
}

.drawer-container,
.main-container,
.right-drawer-container {
  transition: height $default-transition-duration linear;

  position: relative;
  height: var(--viewport-height-100);
  padding: 0;

  overflow: auto;
}

.btn-toggle-drawer {
  z-index: $layer-layout-btn-toggle-drawer !important;

  .btn-toggle-drawer-normal {
    transform: rotate(0deg);
  }
  .btn-toggle-drawer-rotate {
    transform: rotate(900deg);
  }
}

.resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  height: var(--viewport-height-100);
  cursor: ew-resize;
  z-index: 10;
}
</style>
