<template>
  <v-app>
    <site-header v-if="showNewUI" ref="searchBar" layout="document" />
    <search-bar v-else ref="searchBar" layout-type="document" />

    <nuxt />

    <share-url-dialog ref="shareUrlDialog" />
  </v-app>
</template>

<script setup lang="ts">
import { ref, provide, computed } from 'vue';
import { useStore } from '@/store/useStore';
import SearchBar from '@/components/search-bar.vue';
import SiteHeader from '@/components/renewal/header/site-header.vue';
import ShareUrlDialog from '@/components/share/share-url-dialog.vue';

const store = useStore();

const searchBar = ref<InstanceType<typeof SearchBar> | InstanceType<typeof SiteHeader>>();
// /document/_id ではドロワーの表示非表示と連動して search-bar も表示非表示を切り替えたいため provide する
provide('$search-bar', searchBar);

const showNewUI = computed(() => {
  return store.state.persistent.renewal;
});
</script>

<style lang="scss" src="./default.scss" />
