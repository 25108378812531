<template>
  <div class="book-panel">
    <PanelHeader title="書籍・雑誌" :count-loading="loadingCount" :count="totalDocumentCount">
      <template #icon>
        <Icon name="book" size="md" color="blue-600" />
      </template>
    </PanelHeader>
    <div class="book-panel__body">
      <BookSection
        v-if="showRecentlyAdded && !errorRecentlyAddedDocument"
        :loading="loadingRecentlyAddedDocument"
        :title="recentlyAdded.title"
        :books="loadingRecentlyAddedDocument ? booksForLoading : recentlyAdded.books"
        href="/recently-added"
      />
      <template v-if="showFeaturedDocuments">
        <BookSection
          v-for="(section, index) in loadingFeaturedDocument ? featuredDocumentsForLoading : featuredDocuments"
          :key="index"
          :loading="loadingFeaturedDocument"
          :title="section.title"
          :books="section.books"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useRepositories } from '@/plugins/repositories';
import { FeaturedDocRecord, DocRecord } from 'wklr-backend-sdk/models';
import PanelHeader from '@/components/renewal/common/panel-header.vue';
import BookSection from '@/components/renewal/book/book-section.vue';
import { Book } from './book-item.vue';
import Icon from '@/components/renewal/common/icon.vue';
import { useAuth } from '@/plugins/auth';

const auth = useAuth();
const $repositories = useRepositories();

const loadingCount = ref(true);
const loadingFeaturedDocument = ref(true);
const loadingRecentlyAddedDocument = ref(true);
const totalDocumentCount = ref<number | null>(null);
// 組織が personal のユーザーには新着書籍を表示しない
const showRecentlyAdded = computed<boolean>(() => !(auth.user.organization.oid === 'personal'));
const errorRecentlyAddedDocument = ref(false);
// 組織が mhmportal もしくは kenpon の場合は注目書籍を表示しない
const showFeaturedDocuments = computed<boolean>(
  () => !(auth.user.organization.oid === 'mhm-portal' || auth.user.organization.oid === 'kenpon'),
);

type Document = { title: string; books: Book[] };

// 新着書籍
const recentlyAdded = ref<Document>({ title: '', books: [] });
// 注目書籍
const featuredDocuments = ref<Document[]>([]);

// スケルトンローダー用のデータ。UI的にちょうどいい4件分のデータを作成
const booksForLoading = Array.from({ length: 4 }, () => ({} as Book));
const featuredDocumentsForLoading = computed<Document[]>(() => {
  return Array.from({ length: 1 }, () => ({ title: '', books: booksForLoading }));
});

const getTotalDocumentCount = async () => {
  try {
    totalDocumentCount.value = await $repositories.books.getCount();
  } catch (e) {
    console.error(e);
  } finally {
    loadingCount.value = false;
  }
};

const getFeaturedDocumentsList = async () => {
  try {
    const featuredDocumentList = await $repositories.docs.getFeaturedDocumentsLists();
    featuredDocuments.value = featuredDocumentList.map((v: FeaturedDocRecord) => ({
      title: v.name as string,
      books: v.documents.map((document) => ({
        id: document.id,
        url: document?.uri ?? '',
        thumbnailURI: document.thumbnailURI ?? '',
        title: document.title,
        authors: document.authors,
        publisher: document.publisher,
        publishedOn: document.publishedOn,
        type: document.type,
        uri: document.uri,
      })),
    }));
  } catch (e) {
    console.error(e);
  } finally {
    loadingFeaturedDocument.value = false;
  }
};

const getRecentlyAddedDocumentsList = async () => {
  try {
    const recentlyAddedDocumentList = await $repositories.docs.recentlyAdded(10);
    recentlyAdded.value = {
      title: '新着書籍',
      books: recentlyAddedDocumentList.map((v: DocRecord) => ({
        id: v.id,
        url: v.uri,
        thumbnailURI: v.thumbnailURI ?? '',
        title: v.title,
        authors: v.authors,
        publisher: v.publisher,
        publishedOn: v.publishedOn,
      })) as Book[],
    };
  } catch (e) {
    errorRecentlyAddedDocument.value = true;
    console.error(e);
  } finally {
    loadingRecentlyAddedDocument.value = false;
  }
};

onMounted(() => {
  getFeaturedDocumentsList();
  getRecentlyAddedDocumentsList();
  getTotalDocumentCount();
});
</script>

<style lang="scss" scoped src="./book-panel.scss" />
