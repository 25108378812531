import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import DocumentTypeIcon from '@/components/document-type-icon.vue';
import ViewerLink from '@/components/viewer-link.vue';
import { useTelemetry } from '@/plugins/telemetry';
import { ImpressionDetector } from '@/utils/impressionUtils';
import { formatYmd } from '@/utility';
export default defineComponent({
  components: {
    ViewerLink: ViewerLink,
    DocumentTypeIcon: DocumentTypeIcon
  },
  props: {
    selectedReference: {
      type: Object,
      default: null
    },
    handleSelectReference: {
      type: Function,
      required: true
    },
    reference: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    answer: {
      type: [Array, String],
      required: true
    }
  },
  setup: function setup(props) {
    var telemetry = useTelemetry();
    var route = useRoute();
    var intersectionObserver = ref();
    var impressionDetector = ref();
    var showReadMores = ref(Array(props.reference.references.length).fill(false)); // FIXME: true -> false

    onMounted(function () {
      if (!intersectionObserver.value) {
        return;
      }
      impressionDetector.value = new ImpressionDetector(telemetry, route, intersectionObserver.value,
      // 30% 以上の領域が 5000 ミリ秒以上表示されたらインプレッションイベントを送る
      0.3, 5000, 'watson-reference-item', props.reference.record.id);
    });
    onBeforeUnmount(function () {
      var _impressionDetector$v;
      (_impressionDetector$v = impressionDetector.value) === null || _impressionDetector$v === void 0 ? void 0 : _impressionDetector$v.cleanup();
    });
    var handleHover = function handleHover(key, sectionIndex) {
      telemetry.sendHoverTelemetry({
        targetType: 'watson-reference-item__section',
        targetId: props.reference.record.id,
        params: {
          key: key,
          // /document/{naturalId}#key={key}
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          sectionIndex: sectionIndex,
          // 文献の中で何番目に表示されているセクションか
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var handleShowReadMore = function handleShowReadMore(index) {
      showReadMores.value[index] = true;
    };
    var handleClickSection = function handleClickSection(sectionIndex) {
      props.handleSelectReference({
        type: props.reference.record.type,
        document: props.index,
        section: sectionIndex,
        focus: false,
        scroll: false
      });
      telemetry.sendClickTelemetry({
        button: 'watson-reference-item__law',
        params: {
          targetId: props.reference.record.id,
          documentIndex: props.index,
          // Watson の中で何番目の文献か
          isCitedInHolmes: isCitedInHolmes.value
        }
      }, route);
    };
    var isCitedInHolmes = computed(function () {
      if (!Array.isArray(props.answer)) {
        return false;
      }
      return props.answer.some(function (answer) {
        if (answer.ref === undefined) {
          return false;
        }
        return answer.ref.some(function (r) {
          return r.document === props.index;
        });
      });
    });
    var isExpanded = ref(false);
    var sections = computed(function () {
      var previousChunkId = -1;
      var currentSection = [];
      var sections = [];
      props.reference.references.forEach(function (item, index) {
        var _item$id$split = item.id.split('.'),
          _item$id$split2 = _slicedToArray(_item$id$split, 2),
          _ = _item$id$split2[0],
          chunkIdStr = _item$id$split2[1];
        var chunkId = parseInt(chunkIdStr, 10);
        if (index === 0 || chunkId !== previousChunkId + 1) {
          if (currentSection.length > 0) {
            sections.push(currentSection);
            currentSection = [];
          }
        }
        previousChunkId = chunkId;
        var pageNumbersStr = item.pageNumbers.length > 0 ? "p.".concat(item.pageNumbers[0]) : item.pdfPageNumbers.length > 0 ? "p.".concat(item.pdfPageNumbers[0]) : '';
        currentSection.push(_objectSpread(_objectSpread({}, item), {}, {
          sectionId: sections.length,
          pageNumbersStr: pageNumbersStr,
          subIndex: index
        }));
      });
      if (currentSection.length > 0) {
        sections.push(currentSection);
      }
      return sections;
    });
    return {
      handleHover: handleHover,
      handleClickSection: handleClickSection,
      handleShowReadMore: handleShowReadMore,
      showReadMores: showReadMores,
      isExpanded: isExpanded,
      sections: sections,
      formatYmd: formatYmd
    };
  }
});