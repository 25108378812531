import { Component, State, Vue, Watch } from 'nuxt-property-decorator';
import * as Constants from '@/constants';
import { StaticData } from '@/utils/searchUtils';
import { State as MyState } from '@/store';
import { fillWithDefaults, isEqualSearchQuery, isSearchQueryEmpty, scrollToTop } from '@/utility';
import { generateSearchRoute, getPathFromSearchLocation } from '@/utils/routeUtils';
import { SearchOption, SearchQuery, SearchQueryParams, SearchQueryType, PartialSearchQuery } from '@/types/SearchQuery';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { Order } from '@/constants';

@Component
export default class SearchNavigationTab extends Vue {
  /** URLパラメータからロードされる検索キーワード */
  @State((state: MyState) => state.searchBox.keyword) keyword!: string;

  /** URLパラメータからロードされるクエリ */
  @State((state: MyState) => state.search.query) storedQuery!: PartialSearchQuery;

  /** use storedOption, do not use this directly */
  @State((state: MyState) => state.search.orderId) storedOrderId!: number;
  /** use storedOption, do not use this directly */
  @State((state: MyState) => state.persistent.perPage) storedPerPage!: number;

  /** URLパラメータとLocalStorageからロードされる検索オプション */
  get storedOption(): SearchOption {
    return {
      n: this.storedPerPage,
      o: this.storedOrderId,
    };
  }

  /** 検索ボックスに入力されたクエリ */
  get searchKeyword(): string {
    return this.keyword;
  }
  set searchKeyword(value: string) {
    if (value === this.keyword) return;
    this.$store.commit('setSearchKeyword', value);
  }

  /** キーワードとその他のパラメータをまとめたもの。検索の送信などにはこれを利用する */
  get combinedQuery(): SearchQuery {
    return { keyword: this.searchKeyword, ...this.query };
  }

  /** URLパラメータからロードされるtypeクエリ (e.g. 'book') */
  get storedTypeQuery(): SearchQueryType | null {
    if (!this.storedQuery.type) {
      return null;
    }

    return this.storedQuery.type[0];
  }

  get minWidth(): 812 | 'auto' {
    return this.$vuetify.breakpoint.smAndDown ? 'auto' : 812;
  }

  /** フォームで編集中のクエリ */
  query: SearchQueryParams = {
    id: '',
    title: '',
    author: '',
    publisher: [...StaticData.publishers.book, ...StaticData.publishers.web],
    publishedOn: {
      gte: '',
      lte: '',
    },
    type: [...StaticData.types.values],
    tagId: [],
    includeOlderEditions: false,
    maxGaps: Constants.Search.DefaultMaxGaps,
  };

  isQueryIncludesLaw() {
    return this.query.type?.includes('law');
  }

  isQueryIncludesBook() {
    return this.query.type?.includes('book');
  }

  readonly DefaultMaxGaps = Constants.Search.DefaultMaxGaps;
  readonly NoMaxGaps = Constants.Search.NoMaxGaps;

  /** フォームで編集中の検索オプション */
  option: SearchOption = Constants.Search.option;

  /** 並び順で選択できるオプション */
  readonly Orders = Constants.Search.Orders;
  /** ページ番号で選択できるオプション */
  readonly PerPageOptions = Constants.Search.PerPageOptions;

  /**選択中のサービスに対応した出版社一覧 */
  publishers: string[] = [];

  @Watch('storedTypeQuery', { immediate: true })
  async fetchPublishers(): Promise<void> {
    if (this.storedTypeQuery) {
      if (this.storedTypeQuery == DocumentTypeEnum.Law) {
        // Law type does not show publisher candidates.
        this.publishers = [];
      } else {
        this.publishers = await this.$repositories.publishers.listPublisherNames(this.storedTypeQuery);
      }
    } else {
      this.publishers = [];
    }
  }

  /** 詳細検索の内容が変更されているかのフラグ */
  get isQueryChanged(): boolean {
    return (
      this.option.n !== this.storedOption.n ||
      this.option.o !== this.storedOption.o ||
      !isEqualSearchQuery(this.storedQuery, this.combinedQuery)
    );
  }

  /** キーワード以外のパラメータが1つでもあれば詳細検索の見た目をアクティブにする */
  get isAdvancedSearchActive(): boolean {
    return !isSearchQueryEmpty({ ...this.storedQuery, keyword: '', type: [] });
  }

  /** 詳細検索ボタンを表示するかどうかのフラグ */
  get showAdvanceSearchButton(): boolean {
    return !this.$store.state.persistent.renewal;
  }

  /** 詳細検索ダイアログが表示されているかどうかのフラグ */
  get showAdvanceSearch(): boolean {
    return (this.$store.state as MyState).searchBox.showAdvanceSearch;
  }
  set showAdvanceSearch(value: boolean) {
    if (value) {
      this.$store.commit('showAdvancedSearch');
    } else {
      this.$store.commit('hideAdvancedSearch');
    }
  }

  /** 有効なサービス一覧 */
  get services(): { type: SearchQueryType; name: string; to: ReturnType<typeof generateSearchRoute> | string }[] {
    const services: SearchQueryType[] = this.$auth.permissions.legalweb
      ? [DocumentTypeEnum.Book, DocumentTypeEnum.Guideline, DocumentTypeEnum.Pubcom, DocumentTypeEnum.Law]
      : [DocumentTypeEnum.Book];

    return services.map((type) => ({
      type,
      name: StaticData.types.display[type],
      to:
        type === this.storedTypeQuery
          ? this.$route.fullPath
          : /** 書籍と書籍以外ではpublisherクエリを使いまわせないのでリセットする */
          type === DocumentTypeEnum.Book || this.storedTypeQuery === DocumentTypeEnum.Book
          ? generateSearchRoute({ ...this.storedQuery, type: [type], publisher: undefined }, this.storedOption)
          : generateSearchRoute({ ...this.storedQuery, type: [type] }, this.storedOption),
    }));
  }

  created(): void {
    const perPageNumber = this.$route.query['n'];
    if (typeof perPageNumber == 'string') {
      this.option.n =
        Number.parseInt(perPageNumber, 10) > 0 ? Number.parseInt(perPageNumber, 10) : Constants.Search.PerPage;
    }

    const q = this.$route.query['0'];
    if (typeof q == 'string') {
      const order = Constants.Search.Orders[Number.parseInt(q, 10)];
      if (order !== undefined) {
        this.option.o = order.value.id;
      }
    }
  }

  mounted(): void {
    this.showAdvanceSearch = false;
  }

  /**
   * ストアに保存された検索クエリと検索オプションをコンポーネントの state に適用する
   * @param query 更新されたクエリ
   */
  @Watch('showAdvanceSearch')
  applyQueryFromStore(): void {
    // 詳細検索を非表示にする時にはリセットしない
    if (this.showAdvanceSearch === false) return;

    const query = this.storedQuery;
    this.query.title = query.title || '';
    this.query.author = query.author || '';
    this.query.publisher = query.publisher || [];
    this.query.publishedOn.gte = (query.publishedOn && query.publishedOn.gte) || '';
    this.query.publishedOn.lte = (query.publishedOn && query.publishedOn.lte) || '';
    this.query.type = fillWithDefaults(query.type, StaticData.types.values);
    this.query.includeOlderEditions = !!query.includeOlderEditions;
    this.query.maxGaps = query.maxGaps === undefined ? Constants.Search.NoMaxGaps : query.maxGaps;

    const option = this.storedOption;
    this.option.n = option.n || Constants.Search.PerPage;
    this.option.o = option.o || Constants.Search.OrderId;
  }

  // x ボタンで値を消した時に v-text-field が value を null に設定するので空値である '' に置き換える
  @Watch('query.publishedOn.gte')
  nonNullPublishedOnGte(): void {
    if (this.query.publishedOn.gte === null) {
      this.query.publishedOn.gte = '';
    }
  }

  // x ボタンで値を消した時に v-text-field が value を null に設定するので空値である '' に置き換える
  @Watch('query.publishedOn.lte')
  nonNullPublishedOnLte(): void {
    if (this.query.publishedOn.lte === null) {
      this.query.publishedOn.lte = '';
    }
  }

  /**
   * 編集中のクエリの中身をデフォルト値に戻す（キーワード以外は適用済みの条件もクリアされる）
   */
  clearQuery(): void {
    this.query.title = '';
    this.query.author = '';
    this.query.publisher = [];
    this.query.publishedOn.gte = '';
    this.query.publishedOn.lte = '';
    this.query.includeOlderEditions = false;
    this.query.maxGaps = Constants.Search.DefaultMaxGaps;
  }

  /**
   * 検索ボタンが押された時の処理。コンポーネント State を元に画面遷移する
   * @param event
   * @returns
   */
  searchBtnHandler(event: KeyboardEvent | MouseEvent): void {
    if (isSearchQueryEmpty(this.combinedQuery)) {
      return;
    }

    this.showAdvanceSearch = false;

    const { n, o } = this.storedOption;
    const perPage = typeof n === 'string' && Number.parseInt(n, 10) === this.option.n ? undefined : this.option.n;
    const orderId = typeof o === 'string' && Number.parseInt(o, 10) === this.option.o ? undefined : this.option.o;

    const to = generateSearchRoute(this.combinedQuery, this.storedOption, perPage, orderId);

    if (event && (event.ctrlKey || event.metaKey)) {
      // 新規ウィンドウで開いて
      window.open(getPathFromSearchLocation(to));

      // こちらのウィンドウは変えない
      return;
    }

    // 画面上部にスクロールする
    scrollToTop();

    this.$store.commit('setPerPage', perPage);
    this.$store.commit('setSearchOrderId', orderId);
    this.$router.push(to);
  }

  orderItemValue(order: Order) {
    return order.value.id;
  }

  changePublishersHandler(publisher: string, checked: boolean) {
    this.query.publisher = checked
      ? [...(this.query.publisher || []), publisher]
      : this.query.publisher?.filter((p) => p !== publisher) || [];
  }
}
