import { Context } from '@nuxt/types';
import { MutationTree, ActionTree } from 'vuex';
import rison from 'rison';
import { TocInteractive, Viewer } from '@/types/Viewer';
import { DocumentConfig } from '@/types/document-config';
import { DocumentHits } from '@/utils/lookupUtils';
import { Nullable } from '@/types/nullable';
import { PartialSearchQuery } from '@/types/SearchQuery';
import { BookSnippet, DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { generateNewToc, TocNodeExtended } from '@/utils/tocUtils';
import {
  LabsWandhModel,
  LabsWandhStreamParams,
  NonNullableLabsWandhQuestionHistoryListItem,
  isNonNullableLabsWandhQuestionHistoryListItem,
} from 'wklr-backend-sdk/repos/labs';
import { useAuth } from '@/plugins/auth';
import { useRepositories } from '@/plugins/repositories';
import { useWandhGuideline } from '@/plugins/wandhGuideline';

export interface FindState {
  /** 現在のハイライトキーワード */
  keywords: string[];
  hit: HitState;
}

export interface HitState {
  /** 現在のヒット箇所 (0-index) */
  current: number | null;
  /** 現在のヒット位置が属するコンテントの series */
  currentSeries: number | null;
  /** ヒット情報 */
  documentHits: DocumentHits | null;
  /** 巻毎のトータルヒット数 */
  totalHitsByVolume: Record<string, number>;
}

export interface PersistentState {
  /** 検索結果画面１ページあたりの表示件数 */
  perPage: number | null;

  /** 閲覧画面のユーザー設定 */
  documentConfig: DocumentConfig;

  /**
   * PDF見開き notice を表示したことがあるか
   * @note PDF 見開き設定の全 PDF 共通化に伴い、これまで notice を表示したことのある人にも notice を表示する必要があるため hasSeenPdfSpreadModeNotice2 としている
   */
  hasSeenPdfSpreadModeNotice2: boolean;

  /** W&H D1トライアル導線を閉じたか */
  hasClosedWandhD1Trial: boolean;

  /** リニューアル後のUIを表示するか */
  renewal: boolean;

  /** Holmesで参照をタイトル名+セクション番号で表示するか */
  showDetailedReference: boolean;

  /** ニュースパネルを表示するか（legalscape組織のみ） */
  showNewsPanel: boolean;
}

interface LabsWandhQuotaByModel {
  modelId: LabsWandhModel;
  remainingQuota: number | null;
}

interface LabsWandhQuota {
  /**
   * @deprecated 代わりに remainingQuotaByModel を使う
   */
  remainingQuota: number;
  remainingQuotaByModel: {
    standard: LabsWandhQuotaByModel;
    advanced: LabsWandhQuotaByModel;
    experimental: LabsWandhQuotaByModel[];
  };
}

export type InputQuery = {
  model: LabsWandhModel | null | undefined;
  question: string;
  filter: {
    /** 書籍指定検索におけるIDリスト（null ⇔ (iff) 全選択と見なす） */
    id: Record<'store' | 'other', string[] | null>;
    publishedOnOrAfterYear?: number | string;
    genre: string[];
    documentType: Extract<DocumentTypeEnum, 'case' | 'book' | 'guideline' | 'law'>[];
  };
};

export interface LabsState {
  wandh: {
    quota: LabsWandhQuota | null;
    streamParams: LabsWandhStreamParams | null;
    history: NonNullableLabsWandhQuestionHistoryListItem[];
    isInputQueryInitialized: boolean;
    inputQuery: InputQuery;
  };
}

export interface State {
  global: {
    shareUrlDialogDocument: Nullable<DocRecord | BookSnippet>;
    routeChanging: boolean;
    firstAccessOfTheDay: boolean;
  };
  search: {
    query: PartialSearchQuery;
    orderId: number;
    suggestionItems: string[];
    preview: string | false;
    selectedSnippet: string | null;
  };
  searchBox: {
    /** 検索ボックスに入力された値を保持する Store。 コミットの際に state.search.query.keyword に適用される */
    keyword: string;
    /** 詳細検索を表示するかどうか */
    showAdvanceSearch: boolean;
  };
  document: {
    activeViewer: Viewer | null;
    find: FindState;
    toc: Record<string, TocInteractive>;
  };
  persistent: PersistentState;
  labs: LabsState;
}

export const state = (): State => ({
  global: {
    shareUrlDialogDocument: null,
    routeChanging: false,
    firstAccessOfTheDay: true,
  },
  search: {
    query: {},
    orderId: 0,
    suggestionItems: [],
    preview: false,
    selectedSnippet: null,
  },
  searchBox: {
    keyword: '',
    showAdvanceSearch: false,
  },
  document: {
    activeViewer: null,
    find: {
      keywords: [],
      hit: {
        current: null,
        currentSeries: null,
        documentHits: null,
        totalHitsByVolume: {},
      },
    },
    toc: {},
  },
  persistent: {
    perPage: null,
    documentConfig: {
      bodyFontSize: 18,
      maxWidth: 'default',
    },
    hasSeenPdfSpreadModeNotice2: false,
    hasClosedWandhD1Trial: false,
    renewal: false,
    showDetailedReference: false,
    showNewsPanel: true,
  },
  labs: {
    wandh: {
      quota: null,
      streamParams: null,
      history: [],
      isInputQueryInitialized: false,
      inputQuery: {
        model: null,
        question: '',
        filter: {
          id: { store: null, other: null },
          genre: [],
          documentType: ['book', 'guideline', 'law'],
        },
      },
    },
  },
});

export const getters = {};

export const mutations: MutationTree<State> = {
  startRouteChange(state) {
    state.global.routeChanging = true;
  },
  endRouteChange(state) {
    state.global.routeChanging = false;
  },
  setShareUrlDialogDocument(state: State, documentInfo: DocRecord | BookSnippet) {
    state.global.shareUrlDialogDocument = documentInfo;
  },
  clearShareUrlDialogDocument(state: State) {
    state.global.shareUrlDialogDocument = null;
  },
  setSearchKeyword(state: State, keyword: string) {
    state.searchBox.keyword = keyword;
  },
  showAdvancedSearch(state: State) {
    state.searchBox.showAdvanceSearch = true;
  },
  hideAdvancedSearch(state: State) {
    state.searchBox.showAdvanceSearch = false;
  },
  setSearchOrderId(state: State, orderId: number) {
    state.search.orderId = orderId;
  },
  updateSearchQuery(state: State, query: PartialSearchQuery) {
    state.search.query = query;
    state.searchBox.keyword = query.keyword || '';
  },
  setSuggestionItems(state: State, suggestionItems: string[]) {
    state.search.suggestionItems = suggestionItems;
  },
  setPreview(state: State, preview: string | false) {
    state.search.preview = preview;
  },
  setSelectedSnippet(state: State, selected: string | null) {
    state.search.selectedSnippet = selected;
  },
  setActiveViewer(state: State, viewer: Viewer) {
    state.document.activeViewer = viewer;
  },
  setTocList(
    state: State,
    { toc: tocOriginal, openByDefault }: { toc: Record<string, TocNodeExtended>; openByDefault: 'root' | 'all' },
  ) {
    state.document.toc = generateNewToc(tocOriginal, (toc) => ({
      ...toc,
      open: openByDefault === 'all' || toc.isRoot || null,
      sectionHit: null,
      active: false,
    }));
  },
  updateActiveToc(state: State, payload: { docId: string; activeKeys: number[] }) {
    const { docId, activeKeys } = payload;
    const toc = generateNewToc(state.document.toc, (toc) => ({ ...toc, active: activeKeys.includes(toc.key) }), docId);
    state.document.toc = { ...state.document.toc, ...toc };
  },
  updateSectionHits(state: State, { allVolumesHits }: { allVolumesHits: { [key: string]: DocumentHits } }) {
    const toc = generateNewToc(state.document.toc, (toc) => ({
      ...toc,
      sectionHit: allVolumesHits[toc.docId]?.sectionHits[toc.key] || null,
    }));
    state.document.toc = toc;
  },
  clearSectionHits(state: State, payload: { docId: string }) {
    const { docId } = payload;
    const toc = generateNewToc(state.document.toc, (toc) => ({ ...toc, sectionHit: null }), docId);
    state.document.toc = { ...state.document.toc, ...toc };
  },
  toggleTocOpen(state: State, payload: { key: string; isOpen: boolean }) {
    const { key, isOpen } = payload;
    state.document.toc[key].open = isOpen;
  },
  clearFind(state: State) {
    state.document.find = {
      keywords: [],
      hit: {
        current: null,
        currentSeries: null,
        documentHits: null,
        totalHitsByVolume: {},
      },
    };
  },
  requestUpdateHighlights(state: State, keywords: string[]) {
    state.document.find = {
      keywords,
      hit: {
        current: null,
        currentSeries: null,
        documentHits: null,
        totalHitsByVolume: {},
      },
    };
  },
  setHit(
    state: State,
    { documentHits, totalHitsByVolume }: { documentHits: DocumentHits; totalHitsByVolume: Record<string, number> },
  ) {
    state.document.find.hit = {
      current: null,
      currentSeries: null,
      documentHits,
      totalHitsByVolume,
    };
  },
  setCurrentHit(state: State, payload: { current: number | null; currentSeries: number | null }) {
    state.document.find.hit.current = payload.current;
    state.document.find.hit.currentSeries = payload.currentSeries;
  },
  setPerPage(state: State, perPage: number | null) {
    state.persistent.perPage = perPage;
  },
  setBodyFontSize(state: State, bodyFontSize: DocumentConfig['bodyFontSize']) {
    state.persistent.documentConfig.bodyFontSize = bodyFontSize;
  },
  setMaxWidth(state: State, maxWidth: DocumentConfig['maxWidth']) {
    state.persistent.documentConfig.maxWidth = maxWidth;
  },
  setHasSeenPdfSpreadModeNotice(state: State, hasSeenPdfSpreadModeNotice: boolean) {
    state.persistent.hasSeenPdfSpreadModeNotice2 = hasSeenPdfSpreadModeNotice;
  },
  setHasClosedWandhD1Trial(state: State, closed: boolean) {
    state.persistent.hasClosedWandhD1Trial = closed;
  },
  setRenewal(state: State, renewal: boolean) {
    state.persistent.renewal = renewal;
  },
  setShowNewsPanel(state: State, showNewsPanel: boolean) {
    state.persistent.showNewsPanel = showNewsPanel;
  },
  setShowDetailedReference(state: State, showDetailedReference: boolean) {
    state.persistent.showDetailedReference = showDetailedReference;
  },
  setFirstAccessOfTheDay(state: State, flag: boolean) {
    state.global.firstAccessOfTheDay = flag;
  },
  updateLabsWandhQuota(state: State, quota: LabsWandhQuota) {
    state.labs.wandh.quota = quota;
  },
  setLabsWandhStreamParams(state: State, params: LabsWandhStreamParams) {
    state.labs.wandh.streamParams = params;
  },
  resetLabsWandhStreamParams(state: State) {
    state.labs.wandh.streamParams = null;
  },
  setWandhHistory(state: State, history: NonNullableLabsWandhQuestionHistoryListItem[]) {
    state.labs.wandh.history = history;
  },
  setLabsWandhInputQueryInitialized(state: State, initialized: boolean) {
    state.labs.wandh.isInputQueryInitialized = initialized;
  },
  setLabsWandhInputQuery(state: State, inputQuery: InputQuery) {
    state.labs.wandh.inputQuery = inputQuery;
  },
};

export const actions: ActionTree<State, unknown> = {
  /** SSR時に事前にフォームを埋めておくのに必要 */
  nuxtServerInit({ commit }, { route }: Context) {
    if (route.query.q) {
      try {
        commit('updateSearchQuery', rison.decode_object<PartialSearchQuery>(<string>route.query.q));
      } catch (_) {
        /* nop */
      }
    }
  },
  resetWandhHistory({ commit }) {
    commit('setWandhHistory', []);
  },
  initializeWandhInputQuery({ commit, state }) {
    if (state.labs.wandh.isInputQueryInitialized) {
      return;
    }

    const auth = useAuth();
    let documentType = [...state.labs.wandh.inputQuery.filter.documentType];

    const wandhGuideline = useWandhGuideline();
    if (!wandhGuideline) {
      documentType = documentType.filter((type) => !['guideline', 'law'].includes(type)) as Extract<
        DocumentTypeEnum,
        'case' | 'book'
      >[];
    }

    if (auth.permissions.labsQAD1 && !documentType.includes('case')) {
      documentType.push('case');
    }

    commit('setLabsWandhInputQuery', {
      ...state.labs.wandh.inputQuery,
      question: '',
      model: state.labs.wandh.quota?.remainingQuotaByModel.standard.modelId,
      filter: { ...state.labs.wandh.inputQuery.filter, documentType, publishedOnOrAfterYear: undefined },
    });
    commit('setLabsWandhInputQueryInitialized', true);
  },
  async loadWandhHistory({ commit, state }, params: { page: number; pagingSize: number }) {
    const repositories = useRepositories();
    const newItems = await repositories.labs.listLabsWandhQuestionHistories(params.page, params.pagingSize);
    if (newItems.length === 0) {
      return;
    }
    // 不完全なものを除外
    const filtered = newItems.filter(isNonNullableLabsWandhQuestionHistoryListItem);
    commit('setWandhHistory', [...state.labs.wandh.history, ...filtered]);
  },
  async loadLatestWandhHistory({ commit, state }) {
    const repositories = useRepositories();
    const newItems = await repositories.labs.listLabsWandhQuestionHistories(1, 1);
    if (newItems.length === 0) {
      return;
    }
    // 不完全な場合は追加しない
    if (!isNonNullableLabsWandhQuestionHistoryListItem(newItems[0])) {
      return;
    }
    commit('setWandhHistory', [newItems[0], ...state.labs.wandh.history]);
  },
};
