<template>
  <v-app id="wklr-wrapper" :class="classes">
    <!-- 新 UI -->
    <template v-if="showNewUI">
      <div class="layout-renewal">
        <site-header :layout="layoutType" />
        <div id="wklr-wrapper-renewal">
          <template v-if="isTopPage">
            <nuxt />
          </template>
          <template v-else-if="isFoldPage">
            <nuxt />
          </template>
          <template v-else>
            <v-container>
              <nuxt />
            </v-container>
          </template>
        </div>
        <site-footer v-if="!isFoldPage" />
      </div>
    </template>

    <!-- 旧 UI -->
    <template v-else>
      <search-bar :layout-type="layoutType" @show-modest-header="modestHeaderVisible = $event" />
      <debug-component v-if="isDevelopment" />
      <v-container :fluid="isFoldPage">
        <nuxt />
      </v-container>
      <my-footer v-if="!isFoldPage" />
    </template>

    <share-url-dialog ref="shareUrlDialog" />
    <user-profile-wizard-promotion-alert v-if="isUserProfileWizardPromotionAlertVisible" />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import type { Route } from 'vue-router';
import MyFooter from '@/components/base/my-footer.vue';
import SiteFooter from '@/components/renewal/footer/site-footer.vue';
import SiteHeader from '@/components/renewal/header/site-header.vue';
import DebugComponent from '@/components/debug/debug-component.vue';
import SearchBar from '@/components/search-bar.vue';
import ShareUrlDialog from '@/components/share/share-url-dialog.vue';
import UserProfileWizardPromotionAlert from '@/components/alert/user-profile-wizard-promotion-alert.vue';
import { useAuth } from '@/plugins/auth';
import { State as MyState } from '@/store';

const isDevelopment = process.env.NODE_ENV === 'development';

export default Vue.extend({
  components: {
    DebugComponent,
    SearchBar,
    ShareUrlDialog,
    UserProfileWizardPromotionAlert,
    SiteHeader,
    SiteFooter,
    MyFooter,
  },
  data: () => ({
    isDevelopment,
    modestHeaderVisible: false,
    shouldShowInputProfile: false,
  }),
  computed: {
    layoutType() {
      if (this.$route.name === 'index') return 'default';
      if (this.$route.name === 'document-id') return 'modest';
      if (this.$route.name === 'profile-wizard') return 'no-interaction';
      return 'floating';
    },
    classes(): { [key: string]: boolean } {
      return {
        '-floatingHeader': this.layoutType === 'floating' && !this.showNewUI,
        '-modestHeader': this.layoutType === 'modest',
        '-modestHeaderVisible': this.layoutType === 'modest' && this.modestHeaderVisible,
      };
    },
    showNewUI() {
      return this.$store.state.persistent.renewal;
    },
    isTopPage() {
      return this.$route.name === 'index';
    },
    isFoldPage() {
      console.log(this.$route.name);
      return this.$route.name === 'search' || this.$route.name === 'binder-id';
    },
    isUserProfileWizardPromotionAlertVisible(): boolean {
      if (!this.shouldShowInputProfile) return false;
      if (this.$route.path.startsWith('/profile')) return false;
      return (this.$store.state as MyState).global.firstAccessOfTheDay;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (router: Route) {
        this.modestHeaderVisible = false;
      },
    },
  },
  async mounted() {
    const { user } = useAuth();
    this.$data.shouldShowInputProfile = user.flags.shouldShowInputProfile;
  },
});
</script>

<style lang="scss">
@import './default.scss';

#wklr-wrapper {
  // 旧フッターが縦画面サイズが小さい時も下画面に固定されるようにする。.my-footerにmargin-top:autoを指定している。
  display: flex;
  flex-direction: column;

  transition: padding $default-transition-duration linear;

  --header-height: #{$global-header-height};
  --footer-height: #{$global-footer-height};

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    --header-height: #{$global-header-height-sm-xs};
  }

  &.-modestHeader {
    --header-area-offset: 0px;
    --footer-area-offset: 0px;
  }

  &.-floatingHeader {
    --header-area-offset: var(--header-height);
    --footer-area-offset: var(--footer-height);
    padding-top: var(--header-height); // スクロール位置調整用にグローバルヘッダの高さ分

    .v-application--wrap {
      min-height: calc(100vh - var(--header-height));
    }
  }

  // v-dialog がUIのうしろに隠れることの対策
  ::v-deep {
    .v-dialog__container {
      z-index: $layer-global-v-dialog + 1 !important;
    }
    .v-overlay {
      z-index: $layer-global-v-dialog + 2 !important;
    }
    .v-dialog__content {
      z-index: $layer-global-v-dialog + 3 !important;
    }
  }
}

.layout-renewal {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (800px <= width) {
  #wklr-wrapper-renewal {
    padding-top: var(--header-height-lg);
  }
}

@media (600px <= width < 800px) {
  #wklr-wrapper-renewal {
    padding-top: var(--header-height-md);
  }
}

@media (width < 600px) {
  #wklr-wrapper-renewal {
    padding-top: var(--header-height-sm);
  }
}
</style>
