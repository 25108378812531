import type { QuickAccessApi } from '@gen/wklr-backend-api/v1/api';
import type {
  CreateQuickAccessItemDocumentParamsBody,
  CreateQuickAccessItemPdfParamsBody,
  CreateQuickAccessItemWebParamsBody,
  ModifyQuickAccessItemParamsBody,
  QuickAccessItem,
  QuickAccessItemWithBibliography,
} from '@gen/wklr-backend-api/v1/model';

export class QuickAccessRepository {
  constructor(private api: QuickAccessApi) {}

  async createWebItem(
    docId: string,
    key: number,
    headingText: string,
    docDataUpdatedAt: number,
    description: string,
  ): Promise<QuickAccessItem> {
    const requestData: CreateQuickAccessItemWebParamsBody = {
      docId,
      key,
      headingText,
      docDataUpdatedAt,
      description,
    };
    const { data } = await this.api.createQuickAccessItemWeb(requestData);
    return data;
  }

  async createPdfItem(
    docId: string,
    pageSeq: number,
    folio: string,
    docDataUpdatedAt: number,
    description: string,
  ): Promise<QuickAccessItem> {
    const requestData: CreateQuickAccessItemPdfParamsBody = {
      docId,
      pageSeq,
      folio,
      docDataUpdatedAt,
      description,
    };
    const { data } = await this.api.createQuickAccessItemPdf(requestData);
    return data;
  }

  async createDocumentItem(docId: string, docDataUpdatedAt: number, description: string): Promise<QuickAccessItem> {
    const requestData: CreateQuickAccessItemDocumentParamsBody = {
      docId,
      docDataUpdatedAt,
      description,
    };
    const { data } = await this.api.createQuickAccessItemDocument(requestData);
    return data;
  }

  async getItems(): Promise<QuickAccessItemWithBibliography[]> {
    const { data } = await this.api.getQuickAccessItems();
    return data.items;
  }

  async modifyItem(itemId: string, description: string, updatedAt: number): Promise<QuickAccessItem> {
    const requestData: ModifyQuickAccessItemParamsBody = {
      description,
      updatedAt,
    };
    const { data } = await this.api.modifyQuickAccessItem(itemId, requestData);
    return data;
  }

  async deleteItem(itemId: string, updatedAt: number): Promise<void> {
    await this.api.deleteQuickAccessItem(itemId, { updatedAt });
  }

  async getCount(): Promise<number> {
    const { data } = await this.api.getQuickAccessItemCount();
    return data.count;
  }
}
