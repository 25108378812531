<template>
  <div v-if="store.state.persistent.renewal">
    <top />
  </div>

  <div v-else>
    <go-back-to-org-domain />
    <top-banner />
    <portal-sections-list />
    <header-announcement v-if="announcement" :title="announcement.title" :url="announcement.url" />
    <trial-registration-dialog />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';

import HeaderAnnouncement from '@/components/common/header-announcement.vue';
import TrialRegistrationDialog from '@/components/dialog/trial-registration-dialog.vue';
import PortalSectionsList from '@/components/portalSection/portal-sections-list.vue';
import GoBackToOrgDomain from '@/components/portalSection/go-back-to-org-domain.vue';
import TopBanner from '@/components/top-banner.vue';
import Top from '@/components/renewal/top.vue';
import { useStore } from '@/store/useStore';

@Component({
  components: {
    HeaderAnnouncement,
    TrialRegistrationDialog,
    PortalSectionsList,
    GoBackToOrgDomain,
    TopBanner,
    Top,
  },
})
export default class Index extends Vue {
  /** お知らせ */
  announcement: { title: string; url: string } | null = null;

  store = useStore();

  mounted(): void {
    this.$repositories.announcement.getAnnouncement().then((res) => (this.announcement = res));
  }
}
</script>

<style lang="scss">
@import '@/../node_modules/vuetify/src/styles/styles.sass';

.portal-section {
  margin-bottom: 60px;

  p {
    color: rgba(0, 0, 0, 0.56);
  }

  a {
    text-decoration: none;

    &:hover span {
      text-decoration: underline;
    }
  }

  p.noEntry {
    font-size: 14px;
    line-height: 16px;

    color: rgba(0, 0, 0, 0.56);
  }
}
</style>
