<template>
  <footer class="my-footer">
    <v-container class="footer-container">
      <section class="user-contaier">
        <!-- <v-icon color="white" class="accountIcon">mdi-account</v-icon>
        <span class="username">{{ username }}</span
        >さん （<a href="/auth/logout">ログアウトする</a>） -->
        <a href="/auth/logout" @click.prevent="beforeLogout">ログアウトする</a>
      </section>
      <section class="footer-navigation">
        <section class="fnCol footer-navigation-logo-column">
          <h1 class="logo">
            <img v-if="$domain.isLite" src="@/assets/logo-lite.svg" alt="Legalscape Lite" />
            <img v-else src="@/assets/logo.svg" alt="Legalscape" />
          </h1>
          <p class="versionInfo">
            {{ versionInfo }}
          </p>
        </section>
        <section class="fnCol">
          <h2 class="heading">探し方</h2>
          <ul class="linkList">
            <li class="listItem"><nuxt-link to="/">キーワードから探す</nuxt-link></li>
            <li class="listItem"><nuxt-link to="/labs/wandh">リサーチAI “Watson & Holmes β版”</nuxt-link></li>
            <li v-if="showPracticeArea" class="listItem">
              <nuxt-link to="/directories">分野別ニュース</nuxt-link>
            </li>
            <li v-if="!disablePersonalFeature" class="listItem">
              <nuxt-link to="/collections">マイ・コレクション</nuxt-link>
            </li>
            <li class="listItem"><nuxt-link to="/recently-added">新着図書</nuxt-link></li>
          </ul>
        </section>
        <section class="fnCol">
          <h2 class="heading">Legalscapeについて</h2>
          <ul class="linkList">
            <li v-if="manualLink" class="listItem"><a :href="manualLink">マニュアル・使い方が分からないときは</a></li>
            <li v-if="termsURL" class="listItem"><a :href="termsURL">利用規約</a></li>
            <li v-if="privacyPolicy" class="listItem"><a :href="privacyPolicy">プライバシーポリシー</a></li>
            <li class="listItem"><a href="https://www.legalscape.co.jp/">運営会社</a></li>
            <li class="listItem"><a href="https://www.legalscape.co.jp/wklr/licenses">ライセンス情報</a></li>
          </ul>
        </section>
      </section>
    </v-container>
    <small class="copyrights">©2017–{{ new Date().getFullYear() }} Legalscape, Inc.</small>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { AboutLegalscape } from '@/constants';
import { getManualURL } from '@/utils/manual';

@Component({})
export default class MyFooter extends Vue {
  username!: string;

  /** 利用規約のURL 。 null の場合は表示しない */
  termsURL: string | null = null;

  /** プライバシーポリシーの URL 。 null の場合は表示しない */
  privacyPolicy: string | null = null;

  /** バージョン情報（バグ報告用） */
  versionInfo = process.env.versionInfo;

  /** マニュアルURL */
  manualLink: string | null = null;

  get showPracticeArea(): boolean {
    return this.$auth.permissions.practiceArea;
  }

  get disablePersonalFeature(): boolean {
    return this.$domain.isMHMMypage;
  }

  async deleteSessionDevice(): Promise<void> {
    try {
      await this.$repositories.devices.deleteSessionDevice();
    } catch (error) {
      console.log('セッションの端末を削除できませんでした');
      console.log(error);
    }
  }

  async beforeLogout(event: MouseEvent): Promise<void> {
    await this.deleteSessionDevice();
    if (!(event.target instanceof HTMLAnchorElement)) return;
    location.href = event.target.href;
  }

  mounted(): void {
    // TODO: 名前をログイン情報から取得する
    this.username = '甲野 太郎';

    this.manualLink = getManualURL(this);

    // TODO: 2022年11月現在、献本用組織の利用規約は暫定的に非表示となっている
    if (!this.$domain.isKenpon) {
      let termsURL: string;

      switch (true) {
        case this.$domain.isLite:
          termsURL = AboutLegalscape.TERMS_OF_SERVICE_SPECIAL_PACK;
          break;
        case this.$auth.user.organization.sellerId === 2:
          // LegalOn 販売の場合は「Legalscape 追加オプション利用規約」を表示する
          termsURL = AboutLegalscape.TERMS_OF_SERVICE_VIA_LEGALON;
          break;
        default:
          termsURL = AboutLegalscape.TERMS_OF_SERVICE_GENERAL;
      }

      this.termsURL = termsURL;

      // プライバシーポリシーを表示する
      this.privacyPolicy = AboutLegalscape.PRIVACY_POLICY;
    }
  }
}
</script>

<style lang="scss" scoped>
.my-footer {
  width: 100%;
  margin-top: auto;
  padding: 0;
  background: #fafafa;

  > .footer-container {
    margin: 42px auto 0;
    padding: 8px 12px 48px;
    font-size: 13px;
  }

  > .copyrights {
    display: block;
    color: white;
    padding: 7px 32px 8px;
    background: #757575;
  }
}

.user-contaier {
  margin: 0 0 24px;
  padding: 16px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;

  > .accountIcon {
    font-size: 24px;
    background: #757575;
    border-radius: 50%;
    padding: 4px;
    margin-right: 16px;

    &::before {
      // Chrome で確認してアイコン位置の視覚差分を調整
      padding: 0 0 1px 1px;
    }
  }

  > .username {
    font-weight: bold;
  }
}

.footer-navigation {
  width: 100%;
  display: flex;

  > .fnCol {
    flex: 1;
    padding-left: 10px;

    &:first-child {
      padding-left: 0;
    }

    > .heading {
      margin: 0 0 14px;
      padding: 0;
      font-size: 13px;
    }

    > .linkList {
      margin: 0;
      padding: 0;

      > .listItem {
        list-style: none;
        margin: 0;
        padding: 6px 0;

        > a {
          text-decoration: none;
          color: #212121;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.footer-navigation-logo-column {
  > .logo {
    width: 210px;
    margin-bottom: 20px;
  }

  > .versionInfo {
    font-size: 12px;
    color: #757575;
  }
}
</style>
