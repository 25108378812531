<template>
  <div class="site-footer">
    <div class="site-footer__logo">
      <Logo />
    </div>
    <div class="site-footer__contents site-footer__constants--method">
      <h2 class="site-footer__heading">探し方</h2>
      <ul class="site-footer__links">
        <li class="site-footer__link">
          <nuxt-link to="/">キーワードから探す</nuxt-link>
        </li>
        <li class="site-footer__link">
          <nuxt-link to="/labs/wandh">リサーチAI “Watson & Holmes β版”</nuxt-link>
        </li>
        <li v-if="showPracticeArea" class="site-footer__link">
          <nuxt-link to="/directories">分野別ニュース</nuxt-link>
        </li>
        <li v-if="!disablePersonalFeature" class="site-footer__link">
          <nuxt-link to="/collections">マイ・コレクション</nuxt-link>
        </li>
        <li class="site-footer__link">
          <nuxt-link to="/recently-added">新着図書</nuxt-link>
        </li>
      </ul>
    </div>
    <div class="site-footer__contents site-footer__constants--about">
      <h2 class="site-footer__heading">Legalscapeについて</h2>
      <ul class="site-footer__links">
        <li v-if="manualLink" class="site-footer__link">
          <a :href="manualLink" target="_blank">マニュアル・使い方が分からないときは</a>
        </li>
        <li v-if="termsURL" class="site-footer__link">
          <a :href="termsURL" target="_blank">利用規約</a>
        </li>
        <li v-if="privacyPolicy" class="site-footer__link">
          <a :href="privacyPolicy" target="_blank">プライバシーポリシー</a>
        </li>
        <li class="site-footer__link">
          <a href="https://www.legalscape.co.jp/" target="_blank">運営会社</a>
        </li>
        <li class="site-footer__link">
          <a href="https://www.legalscape.co.jp/wklr/licenses" target="_blank">ライセンス情報</a>
        </li>
      </ul>
    </div>
    <div class="site-footer__version">{{ versionInfo }}</div>
    <div class="site-footer__copyright">&copy;2017–{{ year }} Legalscape, Inc.</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useAuth } from '@/plugins/auth';
import { useDomain } from '@/plugins/domain';
import { AboutLegalscape } from '@/constants';
import Logo from '@/components/renewal/common/logo.vue';

/** バージョン情報（バグ報告用） */
const versionInfo = process.env.versionInfo;

const year = new Date().getFullYear();
const { permissions, user } = useAuth();
const { isMHMMypage, isKenpon, isLite } = useDomain();

const showPracticeArea = computed(() => permissions.practiceArea);
const disablePersonalFeature = computed(() => isMHMMypage);
const manualLink = computed(() => {
  if (isKenpon || isLite) {
    // kenpon, Liteはマニュアル無し
    return null;
  } else if (isMHMMypage) {
    return AboutLegalscape.FULLVER_INTRODUCTION_FOR_MHM_MYPAGE;
  } else {
    // MHM向けマニュアル、NO&Tマニュアル、企業(practiceAreaが使用できるorganization)向けマニュアル、法律事務所向けマニュアル、その他
    return 'https://faq.legalscape.jp';
  }
});
const termsURL = computed(() => {
  if (isKenpon) {
    return null;
  } else if (isLite) {
    return AboutLegalscape.TERMS_OF_SERVICE_SPECIAL_PACK;
  } else if (user.organization.sellerId === 2) {
    // LegalOn 販売の場合は「Legalscape 追加オプション利用規約」を表示する
    return AboutLegalscape.TERMS_OF_SERVICE_VIA_LEGALON;
  } else {
    return AboutLegalscape.TERMS_OF_SERVICE_GENERAL;
  }
});
const privacyPolicy = computed(() => {
  if (isKenpon) {
    return null;
  } else {
    return AboutLegalscape.PRIVACY_POLICY;
  }
});
</script>

<style lang="scss" scoped src="./site-footer.scss"></style>
