import { Component, Prop, State, Vue, Watch } from 'nuxt-property-decorator';
import { Route } from 'vue-router';
import { Viewer } from '@/types/Viewer';
import { State as MyState, FindState } from '@/store';
import { supportMailTemplate } from '@/constants';
// FIXME: `throttle-debounce` に変更する
import { debounce } from 'debounce';
import SearchBarHelpMenu from '@/components/search-bar-help-menu.vue';
import ChangelogBadge from '@/components/common/changelog-badge.vue';
import ProfileBadge from '@/components/common/profile-badge.vue';
import { generateSearchRoute, getPathFromSearchLocation } from '@/utils/routeUtils';
import { isSearchQueryEmpty, scrollToTop } from '@/utility';
import * as Constants from '@/constants';
import { PartialSearchQuery, SearchOption } from '@/types/SearchQuery';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { getManualURL } from '@/utils/manual';
import Icon from '@/components/renewal/common/icon.vue';
import { useStore } from '@/store/useStore';
import { ImpressionDetector } from '@/utils/impressionUtils';

const DELAY_TO_HIDE_HEADER_MS = 1000;

@Component({
  components: {
    ChangelogBadge,
    SearchBarHelpMenu,
    ProfileBadge,
    Icon,
  },
})
export default class SearchBar extends Vue {
  @Prop() layoutType!:
    | 'floating' // ページ上部に固定
    | 'modest' // ホバーされていない時は非表示
    | 'default' // 通常（トップページ）
    | 'no-interaction' // インタラクションなし（プロフィール入力ウィザード）
    | 'document'; // 書籍ビューアー向け

  /** URLパラメータからロードされる検索キーワード */
  @State((state: MyState) => state.searchBox.keyword) keyword!: string;

  /** URLパラメータからロードされる検索キーワードを除いたクエリ */
  @State((state: MyState) => state.search.query) storedQuery!: PartialSearchQuery;

  /** 現在利用中のビューアー */
  @State((state: MyState) => state.document.activeViewer) activeViewer!: Viewer;

  /** 文書内検索用データ */
  @State((state: MyState) => state.document.find) find!: FindState;

  @State((state: MyState) => state.search.suggestionItems) storedSuggestionItems!: string[];

  @State((state: MyState) => state.global.routeChanging) routeChanging!: boolean;

  /** モデストレイアウトの場合にヘッダーを表示するかどうかのフラグ */
  showModestHeader = true;

  /**
   * ヘッダーが展開されているかどうか
   * `layoutType` が document のときのみ有効
   */
  collapsed = true;

  shTermsOfUse = 'https://www.shojihomu.co.jp/ekommentar';

  shPrivacyPolicy = 'https://www.shojihomu.co.jp/p005';

  get isDev(): boolean {
    return this.$nuxt.context.isDev;
  }

  get classNames(): { [key: string]: boolean } {
    return {
      '-floating': this.layoutType === 'floating',
      '-modest': this.layoutType === 'modest',
      '-document': this.layoutType === 'document',
      '-modestHeaderVisible': this.showModestHeader,
      collapsed: this.layoutType === 'document' && this.collapsed,
    };
  }

  get suggestionItems(): string[] {
    return this.storedSuggestionItems.filter((v) => v !== this.searchKeyword);
  }

  get isSHMembership(): boolean {
    return this.$domain.isSHKommentar || this.$domain.isSHKommentarLibrary;
  }

  get showSearchBar(): boolean {
    return !this.isSHMembership && this.layoutType !== 'no-interaction';
  }

  get showHelp(): boolean {
    return !this.isSHMembership;
  }

  get showShLinks(): boolean {
    return this.isSHMembership;
  }

  get hideLogo(): boolean {
    return this.isSHMembership;
  }

  /** 検索ボックスに入力されたクエリ */
  get searchKeyword(): string {
    return this.keyword;
  }
  set searchKeyword(value: string | null) {
    if (value === null || value === this.keyword) return;
    this.$store.commit('setSearchKeyword', value);
  }

  /** キーワードとその他のパラメータをまとめたもの。検索の送信などにはこれを利用する */
  get combinedQuery(): PartialSearchQuery {
    return { ...this.storedQuery, keyword: this.searchKeyword };
  }

  /** use storedOption, do not use this directly */
  @State((state: MyState) => state.search.orderId) storedOrderId!: number;
  /** use storedOption, do not use this directly */
  @State((state: MyState) => state.persistent.perPage) storedPerPage!: number;

  /** URLパラメータとLocalStrageからロードされる検索オプション */
  get storedOption(): SearchOption {
    return {
      n: this.storedPerPage || Constants.Search.PerPage,
      o: this.storedOrderId || Constants.Search.OrderId,
    };
  }

  /** マニュアルURL */
  manualLink: string | null = null;

  /** TEMP: for LINE */
  get isForumLinkShown(): boolean {
    return this.$nuxt.context.isDev || this.$domain.isMHM;
  }

  /** クエリが何も設定されていない状態かどうか */
  get isQueryEmpty(): boolean {
    return isSearchQueryEmpty(this.combinedQuery);
  }

  /** 検索ボタンが無効かどうか */
  get isSearchDisabled(): boolean {
    if (this.routeChanging) return true;
    // 検索ページではクエリが空でもその他の条件が指定されていれば検索できる
    if (this.$route.path === '/search') return this.isQueryEmpty;
    // それ以外ではキーワードが空だと検索できない（条件は引き継がれる）
    return !this.combinedQuery.keyword;
  }

  get colorForSearchButton(): 'deep-orange' | 'primary' {
    return this.isDev ? 'deep-orange' : 'primary';
  }

  /** combobox のサジェストに表示される v-menu に渡す props */
  get autoComplateProps(): { contentClass: string; maxWidth: number } {
    const elevationClass = this.layoutType === 'floating' ? 'elevation-0' : 'elevation-1';
    return {
      contentClass: ['autocomplate-wrapper', elevationClass].join(' '),
      maxWidth: this.searchBoxWidth,
    };
  }

  /** 検索ボックスのサイズ */
  searchBoxWidth = 0;

  oid = this.$auth.user.organization.oid;

  /**
   * ユーザーサポートをクリックしたときに開くリンク
   */
  supportEmailURI(encoding = 'utf-8'): string {
    /* https://www.growingwiththeweb.com/2012/07/getting-around-mailto-character-limit.html */
    const body =
      'body=' +
      (encoding === 'utf-8' ? supportMailTemplate.UTF8 : supportMailTemplate.ISO2022Jp) +
      `${location.href} ${process.env.versionInfo} UA: ${navigator.userAgent} C: ${document.documentElement.clientWidth}x${document.documentElement.clientHeight} S: ${screen.width}x${screen.height}`;
    if (this.oid === 'mhm_global') {
      return ('mailto:wg_ls_user-support@mhm-global.com?cc=user-support@legalscape.co.jp&' + body).slice(0, 1900);
    }
    if (this.oid === 'jurists') {
      return ('mailto:user-support@legalscape.co.jp?cc=legalscape_adm@eml.nishimura.com&' + body).slice(0, 1900);
    }
    return 'https://faq.legalscape.jp/kb-tickets/new';
  }

  /**
   * ユーザーサポートがメールか否か
   */
  get isSupportEmail(): boolean {
    return this.oid === 'mhm_global' || this.oid === 'jurists';
  }

  $refs!: { 'intersection-observer': HTMLDivElement; boxSearch: Vue };
  impressionDetector?: ImpressionDetector;

  mounted(): void {
    this.manualLink = getManualURL(this);
    this.$nextTick(() => {
      this.onSearchBoxResize();
      setTimeout(() => {
        this.showModestHeader = false;
      }, DELAY_TO_HIDE_HEADER_MS);
    });

    // 任意の領域が 100 ミリ秒以上表示されたらインプレッションイベントを送る
    this.impressionDetector = new ImpressionDetector(
      this.$telemetry,
      this.$route,
      this.$refs['intersection-observer'],
      0,
      100,
      'site-header',
      '1',
    );
  }

  beforeDestroy(): void {
    this.impressionDetector?.cleanup();
  }

  onSubmit(event: KeyboardEvent | MouseEvent): void {
    if (this.routeChanging || this.isQueryEmpty) {
      return;
    }

    // SERP以外では詳細検索が画面に表示されていないので、以下をデフォルトとして検索する
    const query: PartialSearchQuery =
      this.$route.name !== 'search'
        ? {
            keyword: this.keyword,
            type: [DocumentTypeEnum.Book],
            maxGaps: Constants.Search.DefaultMaxGaps,
            includeOlderEditions: false,
          }
        : this.combinedQuery;
    const to = generateSearchRoute(query, this.storedOption);

    if (event && (event.ctrlKey || event.metaKey)) {
      // 新規ウィンドウで開いて
      window.open(getPathFromSearchLocation(to));

      // こちらのウィンドウは変えない
      return;
    }

    // 画面上部にスクロールする
    scrollToTop();

    this.$router.push(to);
  }

  /** 検索キーワードボックスでEnterキーを押したとき */
  onBoxSearchEnter(event: KeyboardEvent): void {
    // 入力確定のEnterでなければ検索実行
    // FIXME: Safariでは入力確定時でもisComposingがtrueにならないので検索が実行されてしまう
    if (!event.isComposing) {
      this.onSubmit(event);
    }
  }

  searchSuggestionSelectedHandler(on: { click?: () => void }, keyword: string): void {
    on.click?.();

    if (this.routeChanging) return;

    // SERP以外では詳細検索が画面に表示されていないので、詳細条件も適用せずにキーワードのみ指定する
    // このイベントが呼ばれるタイミングではまだフォームの内容は更新されていないので、イベントから渡された値を使う
    const query: PartialSearchQuery =
      this.$route.name !== 'search' ? { keyword, type: [DocumentTypeEnum.Book] } : { ...this.storedQuery, keyword };
    const to = generateSearchRoute(query, this.storedOption);

    // 画面上部にスクロールする
    scrollToTop();

    // FIXME: Vuetify が Event を渡してくれないため ctrlKey と metaKey が取得できず別ウィンドウで開くことができない
    this.$router.push(to);
  }

  /** 検索フォームがリサイズされたタイミングで実行される */
  onSearchBoxResize(): void {
    const searchBox = this.$refs['boxSearch'];
    try {
      if (Array.isArray(searchBox)) throw new Error('$refs.searchBox が複数あります');
      if (searchBox instanceof Element) throw new Error('$refs.searchBox が Vue Component ではありません');
      const el = searchBox?.$el;
      if (el instanceof HTMLElement) {
        this.searchBoxWidth = el.offsetWidth;
      }
    } catch (error) {
      console.error(error);
      console.info(searchBox);
    }
  }

  onModestHeaderActivateBarOverHandler(): void {
    if (this.layoutType !== 'modest') return;
    this.$emit('show-modest-header', true);
    this.showModestHeader = true;
  }

  onMouseLeaveHandler(): void {
    if (this.layoutType === 'modest') {
      this.$emit('show-modest-header', false);
      this.showModestHeader = false;
    }
  }

  /** ページ遷移時はダイアログ閉じる */
  @Watch('$route')
  onRouteChanged(newRoute: Route, oldRoute: Route): void {
    this.$store.commit('setSuggestionItems', []);
    if (newRoute.path !== oldRoute.path) {
      this.showModestHeader = true;
    }
    setTimeout(() => {
      this.onSearchBoxResize();
      setTimeout(() => {
        this.showModestHeader = false;
      }, DELAY_TO_HIDE_HEADER_MS);
    }, Constants.DEFAULT_TRANSITION_DURATION_MS * 1.5);
  }

  /**
   * サジェスト機能
   */
  @Watch('searchKeyword')
  onChangeSearch = debounce(function (this: SearchBar, keyword: string) {
    // READMEのTips参照
    if (keyword === null || keyword.length <= 1) {
      // 1文字以下の時はサジェストを出さない
      this.$store.commit('setSuggestionItems', []);
    } else {
      this.$repositories.searches.getCompleteSearch(keyword).then((res) => {
        this.$store.commit('setSuggestionItems', res);
      });
    }
  }, 500);

  showChangeLog(): boolean {
    return this.layoutType !== 'modest' && !this.$domain.isMHMMypage;
  }
  focusSearchBox(): void {
    // @ts-expect-error boxSearch は Vuetify の VCombobox コンポーネントだが型情報が露出していないので型エラーを無視する
    this.$refs.boxSearch?.focus();
  }

  store = useStore();

  handleToggleRenewal(): void {
    this.store.commit('setRenewal', true);
    this.$telemetry.sendClickTelemetry(
      { button: 'switch-design', params: { 'switch-design__variant': 'v-renewal-2024' } },
      this.$route,
    );
  }
}
