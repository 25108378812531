import { render, staticRenderFns } from "./site-menu-history.vue?vue&type=template&id=13db555c&scoped=true"
import script from "./site-menu-history.ts?vue&type=script&lang=ts&external"
export * from "./site-menu-history.ts?vue&type=script&lang=ts&external"
import style0 from "./site-menu-history.scss?vue&type=style&index=0&id=13db555c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_cache-loader@4.1.0_webpack@4.47.0__css-loader@4.3_255yk7v3heo6y2fxdgr2hz3tmi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13db555c",
  null
  
)

export default component.exports